import React from 'react'
import classNames from 'classnames'

import { RowLayout } from '../../components/layout'
import ActionButton from './ActionButton'

import './free-trial.css'

class FreeTrial extends React.Component {
  render() {
    return (
      <form
        className={classNames('free-trial', 'row')}
        method="get"
        action="https://broadcaster.live365.com/register"
      >
        <RowLayout isResponsive itemSpacing={'1rem'}>
          <input
            className={classNames('text-field')}
            type="text"
            name="name"
            placeholder="Name"
          />

          <input
            className={classNames('text-field')}
            type="email"
            name="email"
            placeholder="Email"
          />

          <ActionButton
            title="Start Free Trial"
            action="submit"
            color="orange"
          />
        </RowLayout>
      </form>
    )
  }
}

export default FreeTrial
