import React, { Component } from 'react'
import classNames from 'classnames'

import NavigationItem from './NavigationItem'

import './navigation-bar.css'

class NavigationBar extends Component {
  render() {
    return (
      <nav className={classNames('navigation-bar', this.props.className)}>
        <ul className={classNames('bar-container', 'alignment-container')}>
          {items.map(item => (
            <NavigationItem
              title={item.title}
              route={item.route}
              key={item.route}
              isActive={item.route === this.props.currentPath}
            />
          ))}
        </ul>
      </nav>
    )
  }
}

const items = [
  { title: 'Overview', route: '/broadcaster/overview' },
  { title: 'Audience', route: '/broadcaster/audience' },
  { title: 'Station Management', route: '/broadcaster/station-management' },
  { title: 'Statistics', route: '/broadcaster/statistics' },
  { title: 'Licensing', route: '/broadcaster/licensing' },
  { title: 'Monetization', route: '/broadcaster/monetization' },
  { title: 'Pricing', route: '/broadcaster/pricing' }
]

export default NavigationBar
