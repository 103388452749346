import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import './page-header.css'

class PageHeader extends Component {
  render() {
    return (
      <header className={classNames('page-header', this.props.className)}>
        <Fade duration={500} delay={100}>
          <div
            className={classNames(
              'foreground',
              'alignment-container',
              'column'
            )}
          >
            <hgroup className="page-headings">
              <h1 className="page-title">{this.props.title}</h1>
              {this.props.subtitle && (
                <h2 className="page-subtitle">{this.props.subtitle}</h2>
              )}
            </hgroup>

            {this.props.content && (
              <div className="page-header-content">{this.props.content}</div>
            )}
          </div>
        </Fade>
      </header>
    )
  }
}

export default PageHeader
