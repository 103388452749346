import React, { Component } from 'react'
import { Link } from 'gatsby'

class NavigationItem extends Component {
  render() {
    var classNames = 'navigation-item-link'
    if (this.props.isActive) {
      classNames += ' active'
    }
    return (
      <li className="navigation-item">
        <Link to={this.props.route} className={classNames}>
          {this.props.title}
        </Link>
      </li>
    )
  }
}

export default NavigationItem
