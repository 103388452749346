import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import ModalVideo from 'react-modal-video'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import PageContent from '../../old/pages/broadcaster/Overview'
import NextRoute from '../../old/components/NextRoute'
import JoinPrompt from '../../old/components/JoinPrompt'
import Contact from '../../old/components/Contact'
import '../../assets/styles.css'
import '../../old/pages/broadcaster.css'
import ActionButton from '../../old/components/ActionButton'
import yellowFloatSmall from '../../old/assets/old-images/overview/hero/yellow2.png'
import blueFloat from '../../old/assets/old-images/overview/hero/blue.png'
import greenFloat from '../../old/assets/old-images/overview/hero/green.png'
import pinkFloat from '../../old/assets/old-images/overview/hero/pink.png'
import yellowFloat from '../../old/assets/old-images/overview/hero/yellow.png'
import blueFloatSmall from '../../old/assets/old-images/overview/hero/blue2.png'

export default class OverviewPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowingModalVideo: false
    }
    this.showModalVideo = this.showModalVideo.bind(this)
  }
  showModalVideo() {
    this.setState({ isShowingModalVideo: true })
  }
  render() {
    const headerContent = (
      <div
        className={classNames(
          'column',
          'broadcaster',
          'overview',
          'page-header'
        )}
        style={{ alignItems: 'center', width: '100%' }}
      >
        <ActionButton
          className={classNames('play-video-button')}
          title="Watch Video"
          icon={playIcon}
          onClick={this.showModalVideo}
          color="orange"
        />
        <div className={classNames('row', 'floating-icons')}>
          <img
            src={yellowFloatSmall}
            className={classNames('floating-icon')}
            style={{
              maxWidth: '35px',
              transform: 'translateY(0) scale(1)',
              transition: 'transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s',
              animation: 'ShortHero 5s ease-out infinite',
              animationDelay: '-3s',
              animationDirection: 'alternate'
            }}
            alt=""
          />

          <img
            src={blueFloat}
            className={classNames('floating-icon')}
            style={{
              maxWidth: '100px',
              transform: 'translateY(0) scale(1)',
              transition: 'transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s',
              animation: 'MediumHero 6s ease-out infinite',
              animationDelay: '-6s',
              animationDirection: 'alternate'
            }}
            alt=""
          />

          <img
            src={greenFloat}
            className={classNames('floating-icon')}
            style={{
              maxWidth: '100px',
              transform: 'translateY(0) scale(1)',
              transition: 'transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s',
              animation: 'LongHero 7s ease-out infinite',
              animationDelay: '-.5s',
              animationDirection: 'alternate'
            }}
            alt=""
          />

          <img
            src={pinkFloat}
            className={classNames('floating-icon')}
            style={{
              maxWidth: '77px',
              transform: 'translateY(0) scale(1)',
              transition: 'transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s',
              animation: 'MediumHero 6s ease-out infinite',
              animationDelay: '-1s',
              animationDirection: 'alternate'
            }}
            alt=""
          />

          <img
            src={yellowFloat}
            className={classNames('floating-icon')}
            style={{
              maxWidth: '143px',
              transform: 'translateY(0) scale(1)',
              transition: 'transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s',
              animation: 'LongHero 7s ease-out infinite',
              animationDelay: '-6s',
              animationDirection: 'alternate'
            }}
            alt=""
          />

          <img
            src={blueFloatSmall}
            className={classNames('floating-icon')}
            style={{
              maxWidth: '35px',
              transform: 'translateY(0) scale(1)',
              transition: 'transform 0.6s cubic-bezier(0.6, 0.2, 0.1, 1) 0s',
              animation: 'ShortHero 5s ease-out infinite',
              animationDirection: 'alternate'
            }}
            alt=""
          />
        </div>
      </div>
    )
    return (
      <React.Fragment>
        <SEO
          title={'Start Your Station - Overview'}
          pathname={this.props.location.pathname}
        />
        <Header
          title={'The Easiest Way to Build a Licensed Online Radio Station'}
          subtitle={'A complete solution to get you broadcasting in minutes.'}
          lightOnDark={true}
          barStyle={'overlay'}
          background={{ color: '#fd6923' }}
        >
          {headerContent}
        </Header>
        <ModalVideo
          isOpen={this.state.isShowingModalVideo}
          channel="vimeo"
          videoId="212993314"
          onClose={() => this.setState({ isShowingModalVideo: false })}
        />
        <PageContent pathname={this.props.location.pathname} />
        <JoinPrompt />
        <NextRoute index={1} />
        <Contact />
        <Footer />
      </React.Fragment>
    )
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const playIcon = (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0L10.5 6 0 12"
      fill="#ffffff"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
    />
  </svg>
)
