import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'
import NavigationBar from '../../components/NavigationBar'
import { RowLayout } from '../../../components/layout'

import './overview.css'

class Overview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isShowingModalVideo: false
    }

    this.showModalVideo = this.showModalVideo.bind(this)
  }

  showModalVideo() {
    this.setState({ isShowingModalVideo: true })
  }

  render() {
    return (
      <Page
        header={null}
        className={classNames('overview', this.props.className)}
        headerTitle="The Easiest Way to Build a Licensed Online Radio Station"
        headerSubtitle="A complete solution to get you broadcasting in minutes."
        lightOnDarkHeader
      >
        <NavigationBar currentPath={this.props.pathname} />
        <PageSection className={classNames('service-information')}>
          <div className={classNames('column', 'padded-section-content')}>
            <RowLayout isResponsive itemSpacing={'4vw'}>
              <Fade top duration={700}>
                <div className={classNames('section-element', 'column')}>
                  <div className="badge green">
                    <svg
                      width="28"
                      height="22"
                      viewBox="0 0 28 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fillRule="nonzero" fill="#333">
                        <path d="M.526 14.987c0 .916.446 1.725 1.126 2.238V12.75c-.68.513-1.126 1.32-1.126 2.237zM22.493 8.79h-.028c-.293-4.395-3.952-7.885-8.42-7.885-4.47 0-8.13 3.49-8.422 7.886h-.028c-1.552 0-2.816 1.265-2.816 2.817v6.76c0 1.553 1.263 2.816 2.815 2.816h2.253c.31 0 .563-.252.563-.563V9.354c0-.31-.25-.563-.562-.563H6.75c.29-3.773 3.447-6.758 7.294-6.758 3.847 0 7.005 2.985 7.294 6.76H20.24c-.31 0-.563.25-.563.562V20.62c0 .31.252.563.563.563h2.253c1.552 0 2.816-1.263 2.816-2.816v-6.76c0-1.552-1.265-2.816-2.817-2.816zM26.436 12.75v4.477c.68-.514 1.126-1.323 1.126-2.24 0-.917-.446-1.724-1.126-2.237z" />
                      </g>
                    </svg>
                  </div>
                  <div className="element-text">
                    <h3 className="element-heading">Audience</h3>
                    <p className="element-description">
                      On mobile, home devices and the web, your station will be
                      in front of a growing audience on the Live365 Radio
                      Network.
                    </p>
                  </div>
                </div>
              </Fade>

              <Fade top duration={700} delay={100}>
                <div className={classNames('section-element', 'column')}>
                  <div className="badge blue">
                    <svg
                      width="19"
                      height="24"
                      viewBox="0 0 19 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.857 4.886c-.18.074-.3.25-.3.445v11.056H4.155C2.3 16.386.79 17.896.79 19.75c0 1.857 1.51 3.366 3.364 3.366 1.793 0 3.365-1.573 3.365-3.365V9.02l9.613-3.933v7.456H14.73c-1.856 0-3.365 1.51-3.365 3.365 0 1.856 1.51 3.365 3.364 3.365 1.792 0 3.364-1.573 3.364-3.365v-14.9c0-.34-.347-.575-.663-.445L6.858 4.886z"
                        fillRule="nonzero"
                        fill="#333"
                      />
                    </svg>
                  </div>
                  <div className="element-text">
                    <h3 className="element-heading">Licensing</h3>
                    <p className="element-description">
                      Live365 covers your listeners in the USA (through
                      SoundExchange, ASCAP, BMI, and SESAC), in Canada (through
                      SOCAN and Re:Sound), and in the UK (through PPL and PRS
                      for Music).
                    </p>
                  </div>
                </div>
              </Fade>

              <Fade top duration={700} delay={200}>
                <div className={classNames('section-element', 'column')}>
                  <div className="badge yellow">
                    <svg
                      width="14"
                      height="28"
                      viewBox="0 0 14 28"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.893 1.115v1.468c.95.1 1.767.3 2.447.598.68.3 1.273.753 1.78 1.36.4.462.71.938.927 1.427.217.49.326.938.326 1.346 0 .453-.162.845-.486 1.175-.325.33-.718.496-1.18.496-.87 0-1.436-.48-1.694-1.44-.293-1.133-1-1.885-2.12-2.257v5.655c1.103.308 1.98.59 2.634.842.653.254 1.237.62 1.753 1.1.55.5.976 1.096 1.273 1.79.298.692.447 1.45.447 2.276 0 1.033-.238 2-.713 2.9-.476.903-1.176 1.64-2.1 2.21-.925.57-2.023.91-3.294 1.02v3.384c0 .535-.05.924-.153 1.17-.102.244-.327.366-.673.366-.32 0-.545-.1-.674-.3-.13-.198-.193-.506-.193-.923v-3.67c-1.04-.118-1.95-.367-2.727-.748-.777-.38-1.426-.855-1.946-1.42-.52-.568-.905-1.157-1.154-1.768C.123 18.56 0 17.96 0 17.372c0-.436.167-.828.5-1.177.333-.35.75-.523 1.247-.523.4 0 .737.095 1.013.285.276.19.467.458.573.802.24.742.45 1.31.627 1.705.178.394.447.754.807 1.08.36.327.837.576 1.433.748v-6.32c-1.19-.335-2.187-.71-2.987-1.122-.8-.41-1.45-.998-1.946-1.76C.77 10.33.52 9.35.52 8.156c0-1.558.487-2.836 1.46-3.833.973-.996 2.38-1.576 4.22-1.74v-1.44C6.2.382 6.48 0 7.04 0c.57 0 .853.372.853 1.115zM6.2 10.44V5.232c-.747.227-1.33.526-1.747.897-.417.372-.626.933-.626 1.686 0 .715.195 1.257.586 1.624.39.367.987.7 1.787 1zm1.693 4.036v5.953c.898-.182 1.59-.55 2.08-1.102.49-.553.734-1.196.734-1.93 0-.788-.238-1.398-.714-1.828-.475-.43-1.175-.795-2.1-1.094z"
                        fill="#333"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="element-text">
                    <h3 className="element-heading">Monetization</h3>
                    <p className="element-description">
                      Our simple targeted audio ads lower your monthly cost and
                      share revenue back to your station.
                    </p>
                  </div>
                </div>
              </Fade>
            </RowLayout>
            <RowLayout isResponsive itemSpacing={'4vw'}>
              <Fade top duration={700} delay={300}>
                <div className={classNames('section-element', 'column')}>
                  <div className="badge orange">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fillRule="nonzero" fill="#333">
                        <path d="M20 2H5c-.552 0-1-.448-1-1s.448-1 1-1h15c.552 0 1 .448 1 1s-.448 1-1 1zM20 6H5c-.552 0-1-.448-1-1s.448-1 1-1h15c.552 0 1 .448 1 1s-.448 1-1 1zM19 10H5c-.552 0-1-.448-1-1s.448-1 1-1h14c.552 0 1 .448 1 1s-.448 1-1 1zM13 14H5c-.552 0-1-.448-1-1s.448-1 1-1h8c.552 0 1 .448 1 1s-.448 1-1 1zM12.5 18H5c-.552 0-1-.448-1-1s.448-1 1-1h7.5c.552 0 1 .448 1 1s-.448 1-1 1zM2 2H1c-.552 0-1-.448-1-1s.448-1 1-1h1c.552 0 1 .448 1 1s-.448 1-1 1zM2 6H1c-.552 0-1-.448-1-1s.448-1 1-1h1c.552 0 1 .448 1 1s-.448 1-1 1zM2 10H1c-.552 0-1-.448-1-1s.448-1 1-1h1c.552 0 1 .448 1 1s-.448 1-1 1zM2 14H1c-.552 0-1-.448-1-1s.448-1 1-1h1c.552 0 1 .448 1 1s-.448 1-1 1zM2 18H1c-.552 0-1-.448-1-1s.448-1 1-1h1c.552 0 1 .448 1 1s-.448 1-1 1zM23.223 10.098l-6 4c-.14.092-.223.25-.223.416V19h-1.5c-1.208 0-2.5.66-2.5 2.507 0 1.848 1.292 2.507 2.5 2.507.996 0 2.5 0 2.5-1.5V14.78l5-3.332V16h-1.5c-1.208 0-2.5.66-2.5 2.507 0 1.848 1.292 2.507 2.5 2.507.996 0 2.5 0 2.5-1.5v-9c0-.397-.443-.638-.777-.416z" />
                      </g>
                    </svg>
                  </div>
                  <div className="element-text">
                    <h3 className="element-heading">Station Management</h3>
                    <p className="element-description">
                      Access your station remotely to upload tracks, build
                      playlists and schedule your 24/7 automation. Forgot to
                      schedule a show? Don’t worry, our Auto DJ will build a
                      DMCA compliant playlist for you.
                    </p>
                  </div>
                </div>
              </Fade>

              <Fade top duration={700} delay={400}>
                <div className={classNames('section-element', 'column')}>
                  <div className="badge purple">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fillRule="nonzero" fill="#333">
                        <path d="M21.244 20.792h-.452V7.684c0-.25-.203-.452-.452-.452h-2.712c-.25 0-.452.203-.452.452v13.108h-1.808v-6.78c0-.25-.203-.452-.452-.452h-2.712c-.25 0-.452.203-.452.452v6.78H9.944v-8.588c0-.25-.203-.452-.452-.452H6.78c-.25 0-.452.203-.452.452v8.588H4.52v-4.068c0-.25-.203-.452-.452-.452H1.356c-.25 0-.452.203-.452.452v4.068H.452c-.25 0-.452.203-.452.452 0 .25.203.452.452.452h20.792c.25 0 .452-.203.452-.452 0-.25-.203-.452-.452-.452z" />
                        <path d="M2.712 11.752c.914 0 1.606-.908 1.267-1.824l3.467-2.89c.62.367 1.343.19 1.752-.33l3.007 1c.014.736.614 1.332 1.353 1.332 1.01 0 1.708-1.094 1.17-2.028L18.5 2.617c.908.354 1.842-.332 1.842-1.26C20.34.606 19.73 0 18.984 0c-1.014 0-1.705 1.097-1.17 2.028l-3.767 4.394c-.592-.225-1.19-.026-1.55.43l-3.008-1c-.015-.736-.615-1.332-1.354-1.332-.925 0-1.604.924-1.267 1.824L3.4 9.234c-.92-.546-2.044.132-2.044 1.162 0 .748.608 1.356 1.356 1.356z" />
                      </g>
                    </svg>
                  </div>
                  <div className="element-text">
                    <h3 className="element-heading">Statistics</h3>
                    <p className="element-description">
                      Real time access to listener numbers allows you to see
                      which shows are the most popular.
                    </p>
                  </div>
                </div>
              </Fade>

              <Fade top duration={700} delay={500}>
                <div className={classNames('section-element', 'column')}>
                  <div className="badge red">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fillRule="nonzero" fill="#333">
                        <path d="M17.603 14.97l-2.328-2.322-2.617 2.622 2.336 2.33c-.504 1.494-.13 3.136.998 4.26 1.208 1.21 2.926 1.55 4.49.888.142-.06.245-.188.273-.34.03-.154-.022-.31-.133-.418l-1.694-1.63v-1.437h1.437l1.63 1.694c.11.113.267.16.417.134.154-.027.28-.13.342-.273.657-1.553.31-3.328-.886-4.523-1.122-1.12-2.768-1.49-4.265-.983zM6.404 9.03l2.325 2.32 2.616-2.623-2.33-2.325c.503-1.492.127-3.136-1-4.26C6.81.93 5.09.59 3.526 1.25c-.142.06-.244.19-.273.342-.03.152.022.308.134.416L5.08 3.642V5.08H3.644l-1.63-1.696c-.108-.113-.266-.162-.417-.134-.152.03-.28.13-.34.274-.657 1.552-.31 3.328.886 4.522 1.12 1.122 2.764 1.492 4.262.984z" />
                        <path d="M9.033 17.6c.51 1.498.138 3.143-.983 4.264-1.195 1.194-2.97 1.542-4.523.886-.143-.06-.245-.188-.274-.34-.028-.152.02-.31.134-.418l1.694-1.63v-1.438H3.644l-1.63 1.694c-.11.112-.265.162-.417.134-.152-.028-.28-.13-.342-.274-.66-1.562-.32-3.28.888-4.49 1.125-1.126 2.768-1.502 4.26-.998l8.57-8.588c-.507-1.5-.136-3.144.984-4.262 1.196-1.197 2.97-1.545 4.523-.888.142.06.245.188.274.34.027.153-.023.31-.134.418l-1.694 1.63V5.08h1.437l1.63-1.694c.11-.112.265-.162.417-.134.154.03.28.13.342.274.662 1.562.32 3.28-.887 4.49-1.125 1.126-2.768 1.502-4.26.998L9.032 17.6z" />
                      </g>
                    </svg>
                  </div>
                  <div className="element-text">
                    <h3 className="element-heading">Software Agnostic</h3>
                    <p className="element-description">
                      Use your favorite software tools. We support any Icecast2
                      compliant encoder.
                    </p>
                  </div>
                </div>
              </Fade>
            </RowLayout>
            <Fade top duration={700} delay={500}>
              <div className="section-element">
                For AM/FM Radio Broadcasters,{' '}
                <a href="https://live365.com/go/am-fm-radio-streaming/">
                  click here
                </a>
                .
              </div>
            </Fade>
          </div>
        </PageSection>

        {this.props.children}
      </Page>
    )
  }
}

export default Overview
