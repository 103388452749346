import React from 'react'
import classNames from 'classnames'

import './page-section.css'

class PageSection extends React.Component {
  render() {
    return (
      <section
        {...this.props}
        className={classNames('page-section', this.props.className)}
      >
        <div className={classNames('alignment-container')}>
          {this.props.children}
        </div>
      </section>
    )
  }
}

export default PageSection
