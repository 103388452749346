import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import PageSection from '../templates/PageSection'
import ActionButton from './ActionButton'

export default ({ index }) => (
  <PageSection className={classNames('broadcaster', 'next-route')}>
    <div className={classNames('column', 'padded-section-content')}>
      <Fade top duration={700} fraction={0.9}>
        <p>{routes[index].description}</p>
        <ActionButton
          title={'Next: ' + routes[index].title}
          route={routes[index].path}
          color="orange"
        />
      </Fade>
    </div>
  </PageSection>
)

const routes = [
  {
    path: '/broadcaster/overview'
  },
  {
    path: '/broadcaster/audience',
    title: 'Audience',
    description:
      'Find out more about how Live365 can help you grow your station.'
  },
  {
    path: '/broadcaster/station-management',
    title: 'Station Management',
    description:
      'Learn more about Live365’s tools to remotely manage your station.'
  },
  {
    path: '/broadcaster/statistics',
    title: 'Statistics',
    description: 'Find out about Live365’s powerful statistics.'
  },
  {
    path: '/broadcaster/licensing',
    title: 'Licensing',
    description:
      'Music licensing legalities can be overwhelming. We have you covered.'
  },
  {
    path: '/broadcaster/monetization',
    title: 'Monetization',
    description:
      'Live365 provides your station with ads targeted to your listeners.'
  },
  {
    path: '/broadcaster/pricing',
    title: 'Pricing',
    description: 'Start broadcasting today!'
  }
]
