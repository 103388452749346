import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

export default ({
  title,
  ignoreTitleTemplate,
  description,
  image,
  isRawImageURL,
  pathname,
  scripts
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername
        }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: isRawImageURL ? image : `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`
      }

      return (
        <React.Fragment>
          <Helmet
            title={seo.title}
            titleTemplate={ignoreTitleTemplate ? '%s' : titleTemplate}
          >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {scripts &&
              scripts.map(script => {
                if (script.src) {
                  return <script src={script.src} type="text/javascript" />
                } else if (script.inline) {
                  return (
                    <script type="text/javascript">{`
                    ${script.inline}
                `}</script>
                  )
                } else {
                  return null
                }
              })}
          </Helmet>
        </React.Fragment>
      )
    }}
  />
)

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
