import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import PageSection from '../templates/PageSection'
import FreeTrial from './FreeTrial'

export default () => (
  <PageSection
    className={classNames(
      'broadcaster',
      'join-prompt',
      'with-background-image',
      'light-on-dark'
    )}
  >
    <div className={classNames('column', 'padded-section-content')}>
      <Fade top duration={700} fraction={0.9}>
        <h1 className={classNames('section-title')}>
          Join us and start broadcasting today.
        </h1>
        <FreeTrial />
      </Fade>
    </div>
  </PageSection>
)
