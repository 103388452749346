import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import './action-button.css'

class ActionButton extends React.Component {
  render() {
    let buttonClassNames = classNames(this.props.className, {
      'action-button': true,
      'light-on-dark': true,
      [`${this.props.color}`]: this.props.color,
      'has-icon': this.props.icon
    })

    return (
      <React.Fragment>
        {this.props.route && (
          <Link to={this.props.route} className={buttonClassNames}>
            {this.props.title}
          </Link>
        )}

        {this.props.action && (
          <button type={this.props.action} className={buttonClassNames}>
            {this.props.title}
          </button>
        )}

        {this.props.link && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.link}
            className={buttonClassNames}
          >
            {this.props.icon && (
              <span className={classNames('button-icon')}>
                {this.props.icon}
              </span>
            )}
            {this.props.title}
          </a>
        )}

        {this.props.onClick && (
          <button onClick={this.props.onClick} className={buttonClassNames}>
            {this.props.icon && (
              <span className={classNames('button-icon')}>
                {this.props.icon}
              </span>
            )}
            {this.props.title}
          </button>
        )}
      </React.Fragment>
    )
  }
}

export default ActionButton
