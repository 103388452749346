import React from 'react'
import classNames from 'classnames'

import PageHeader from './PageHeader'
// import PageFooter from './PageFooter'

class Page extends React.Component {
  render() {
    let headerClassNames = classNames(
      this.props.className,
      { 'with-background-image': this.props.withHeaderBackgroundImage },
      {
        'light-on-dark':
          this.props.lightOnDarkHeader || this.props.withHeaderBackgroundImage
      }
    )

    return (
      <React.Fragment>
        {this.props.header === undefined && (
          <PageHeader
            className={headerClassNames}
            title={this.props.headerTitle}
            subtitle={this.props.headerSubtitle}
            content={this.props.headerContent}
          />
        )}

        {this.props.header}

        {this.props.children &&
          React.Children.map(this.props.children, (child, i) => {
            return child !== null
              ? React.cloneElement(child, {
                  className: classNames(
                    this.props.className,
                    child.props.className
                  )
                })
              : null
          })}

        {/*<PageFooter
        className={classNames(this.props.className)}
      />*/}
      </React.Fragment>
    )
  }
}

export default Page
