import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import ActionButton from './ActionButton'

import './contact.css'
import amp from '../assets/old-images/amp@2x.png'

class Contact extends React.Component {
  render() {
    return (
      <div className={classNames('contact', 'column', this.props.className)}>
        <div className={classNames('padded-section-content')}>
          <div className={classNames('alignment-container', 'column')}>
            <h1 className={classNames('heading')}>
              {this.props.title ? this.props.title : 'Contact Us'}
            </h1>
            <p className={classNames('sub-heading')}>
              {this.props.description
                ? this.props.description
                : 'We’d love to talk about how we can help you.'}
            </p>
          </div>

          <div className={classNames('row', 'form')}>
            <Fade duration={700}>
              <img src={amp} alt="amp" />
            </Fade>

            <Fade top cascade duration={700} fraction={0.9}>
              <form
                className="column"
                method="post"
                action="https://api.live365.com/contact-form"
                encType="multipart/form-data"
              >
                <input
                  className={classNames('text-field')}
                  type="text"
                  placeholder="Name"
                  name="name"
                  required
                />
                <input
                  className={classNames('text-field')}
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                />
                <input
                  className={classNames('text-field')}
                  type="text"
                  placeholder="Subject"
                  defaultValue={this.props.subject || ''}
                  name="subject"
                />
                <textarea
                  className={classNames('text-field')}
                  placeholder="Your message"
                  name="message"
                  required
                />

                <ActionButton
                  title="Send Message"
                  action="submit"
                  color="orange"
                />
              </form>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
